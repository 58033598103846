<template>
  <!-- 29-10-2020 [DaWo]: Either its the default arrow or an provided icon -->
  <!-- but never the text icon -->
  <!-- <base-item-group :item="item" text sub-group /> -->
  <base-item-group :item="item" sub-group />
</template>

<script>
export default {
  name: 'ItemSubGroup',
  components: {
    BaseItemGroup: () => import('./ItemGroup.vue'),
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
  },
  data() {
    return {
      activeColor: 'red',
    };
  },
  methods: {
    setActiveColor() {
      this.activeColor = this.$vuetify.theme.dark ? 'white' : 'black';
    },
  },
  created() {
    this.setActiveColor();
  },
};
</script>
<style scoped>
.v-list .v-list-item--active .v-list-item__title {
  color: var(--v-activeColor);
}
.v-list .v-list-item__title {
  color: var(--v-activeColor);
}
</style>
